.SecondaryHeader {
	header {
		text-align: center;
		background-color: black;
		height: 80px;
	}

	header img {
		height: 80px;
	}
}
