.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) {
  .container {
    width: 720px;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    width: 960px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    width: 1320px;
  }
}
