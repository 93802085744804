.MainHeader {
	header {
		text-align: center;
		padding-top: 3rem;
		padding-bottom: 1rem;
	}

	header img {
		height: 140px;
	}
}
