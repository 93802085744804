.ApartmentDetails {
	display: flex;
	flex-direction: column;

	.imageContainer {
		display: flex;
	}

	.imageContainer .image1 {
		flex: 2;
	}

	.imageContainer .image2 {
		flex: 6;
	}

	.imageContainer img {
		width: 100%;
	}

	.details {
		margin: 2rem;
	}

	.details ul {
		margin-bottom: 2rem;
	}

	.details ul li {
		display: flex;
		align-items: center;
	}

	.details .icon {
		display: inline-block;
		width: 40px;
		height: 32px;
		margin-right: 1rem;
		background-size: contain;
		background-repeat: no-repeat;
	}

	.icon.icon-bed {
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFIAAAA6CAAAAAAG4FFwAAAB1UlEQVR4Ae3YA6wcURTG8e/O3Du13YZtuEFtG7Ea1LZtRuuoNsPato2otm084zydtfEPFif3t94B0oJe+Mgk+ePq+k5Dx1Gj2qw8/8UPkrBpXaoLKE0qSmoKqNZh8tpL33wjfzsGZGJSwkUyCx64+Kd35P+D/UoKHV6kiWI9d/3xRKacGlaOPK/UUv0O/3dDXp5QBRI+JkW5kadSWfLE5JpQ8CuJKmP3MWQlHQGkl2LIhgioOkkySeaSOopEd0owSU+kaLT7UZGuzhVZw/Hni84OdfBEFvuexjVAA7Ru7OhfeQ+kKY1tIzJIKz/r7IHswS87DkBu52fjXJINsibdfCfHILPa3D8RPRj7wg1DWPhZc2RWkiEfXLx+/fq55/yyd0MdjmH3+dmb0xkLL98tTKYutawNMKftf35ycSWo4gGmUH58HplaGUGp5B8ilyM4iXlEWlVwSDmDyLXFg0Majqgmk2SSXKCroKSPIfJsx3FBqdsOItPefApKb1KInIEg1Y/I1UZwRGUhck2xIJF2IqcgSPUmMuX6o0enAdR75GftAWx99OjWLyIzewSgaZqf9QBwPOta7JE3UFzW8pc06SWwtQj5a7B91lJ/yc3THIM+JPzpknRzFSP/JUBpEQAAAABJRU5ErkJggg==);
	}

	.icon.icon-bath {
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFIAAAA6CAAAAAAG4FFwAAABIklEQVR4AWP4T3UwsEaOGjlq5KiRo0aOGrlMypwqQKYfbmQPCwNVAHM53Mj5HNQxkg3hynns1DGStQduZAUDlUAc3Mi/1+5zUsHA+1d/IyciLspNBBk1ko0cNZKJ+kZqU26iOJqROcwUGxmMZuR8VoqLoS40Iy9S7vG9aEb+ZqTYyA/odU8ehYHJEolRnf3WoSgdMcp+xawhL1PmzEPYKt0GRgocmYa1Hv9pTL6Jmm9xNA22CLOQFTO8S3C3Nt5HM5AaokANXs/xNmCm5tvzMTAyMLJyEARsjECFnBa5HUS0iR4qM7BHzicIktkY+G/9I7KZZczA2f+fIJjPASp8hpWRGgysTYSNnMrKIES0kZr8nLWEjZzOISBDk1bwqJGjRgIAWBVubc3ZzwMAAAAASUVORK5CYII=);
	}

	.icon.icon-car {
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFIAAAA6CAAAAAAG4FFwAAACWklEQVR4Ae3TQ6DdXhDH8d+cJFd/27Zt1G431ba2bdu2bdtuN7Vt275viiTX0bM+weqc72oGHMPtDW2y19CUzlbDQK5Gq65zDNHJuMbPExShaGShGBAK6PlKfuvksSwCj5FiSeAx8dt+q2Rdtwwh0edZa1jK+ZUQEiSlrHlyhwDo/6mX2JZrswoSgNVmyQffSqD27MBYAr13yyTZxEFRMxSQKxgnbwKiKDtUmYCDhsm1AA6wQ5cIYpphspcLb7Jj30BpbJgsTlIpdqyRgn8Nkz5Qc3ZsCCAbJj+CPJ0dWwG8apj8BMqMeCVfS0PJzGRm8sZAN+B6zbEXAbnHlRjJgzm8MuJNdmXdEpk8/6FAgtBrJ8KT5z9XkEDSOydCk/7nZSSY5LkdkpxHSARiVEgyj0Bi+CmY3ENIHCsDyfoCiUKUDCRHeJAoXD2SKJmZJHrhp699JMExQd7Pf3mBKDJJnx5lZv+i7HDsjzn3mfncTxSRxB5WNRVwRCrPqnMiIvkxa+LyEhygXx6y5s/wpCjCuoVwQhrHuioiLEmFWDfXWXIE68pJYUm84GdNM4JKyCYEaYfKsO4DhCcxmlX+t6GSWs8w0cWjNZ+7w6rFiEzKjfZeuXLl7OgvoZnOppYRVO8NOfX44sGOPhGZ1IacoGMLL0Mj1D9ZL2Qcm3vOdCH7uxCFqrCploQocutAsmvsIS5h4h9CDPUCSV7wrIwEk9yTOJjkI98Sgl56w6ZXEEQf7uLQJN9BiBVs0xaE2MnhSU548kiMZGZyCdu0yTQpebw6MZ1t2iC8Oo84zqpHMEPtn6pE9rkAAAAASUVORK5CYII=);
	}

	.details table {
		margin-bottom: 2rem;
	}

	.details table th,
	.details table td {
		padding-right: 1rem;
		text-align: left;
	}

	.details .status {
		text-transform: uppercase;
		font-size: 1.3rem;
	}

	.details .status .thin {
		font-weight: 400;
	}

	@media only screen and (min-width: 768px) {
		& {
			flex-direction: row;
		}

		.imageContainer {
			flex: 5;
		}

		.details {
			flex: 2;
		}
	}
}
