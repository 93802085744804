.Table {
	table {
		min-width: 720px;
		width: 100%;
		background-color: #dddddd;
		color: black;
		border-collapse: collapse;
	}

	table a {
		color: inherit;
	}

	table th {
		color: white;
		background-color: black;
	}

	table tr th,
	table tr td {
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 4px;
		padding-bottom: 4px;
		border: 1px solid black;
	}

	.sold {
		background-color: #bd0417;
	}

	.under-offer {
		background-color: #bd0417;
	}
}
